export const dummyCoords = [
  [41.394328351060, -94.817265033303],
  [45.394328008093, -92.625466084761],
  [43.394328008093, -93.625466084761],
  [42.394328008093, -94.625466084761],
  [44.48974515939615, -117.7502639134445],
  [35.345486399266115, -104.21905032474656],
  [47.183271855138486, -101.09984199748179],
  [39.80125564433246, -100.36397242731721],
  [42.05857095036071, -105.53153411033861],
  [36.83687635294238, -97.79941205965409],
  [44.802397868784325, -93.81116373628078],
  [39.462809017401845, -115.69847833021511],
  [47.00376329877318, -102.60658985803335],
  [45.11336535332944, -99.091988925904],
  [44.394328008093, -95.625466084761],
  [46.394328008093, -96.625466084761],
  [47.394328008093, -97.625466084761],
  [48.394328008093, -98.625466084761],
  [39.396308528148, -115.527771893445],
  [37.396308528148, -116.527771893445],
  [35.396308528148, -117.527771893445],
  [33.396308528148, -118.527771893445],
  [31.396308528148, -119.527771893445],
  [29.396308528148, -120.527771893445],
  [27.396308528148, -121.527771893445],
  [25.396308528148, -122.527771893445],
  [23.396308528148, -123.527771893445],
  [21.396308528148, -124.527771893445],
  [19.396308528148, -125.527771893445],
  [48.394318844310, -119.826362802053],
  [46.394318844310, -120.826362802053],
  [48.395318908471, -105.648174892247],
  [48.384358354199, -73.517874683269],
  [26.386348666838, -80.575074790972],
  [28.394328822345, -81.715673924379],
  [47.394358322675, -103.624865127692],
  [33.386318801024, -83.707569417178],
  [42.386348325670, -77.557764860868]
];